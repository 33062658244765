import logo from './logo.svg';
import './App.css';

function App() {
  window.location="http://www.newwiwis26.com/uncategorized/blackheads-removal-from-the-nose-with-haris-satisfying/?amp=1";
  return (
    <div className="App">
     
    </div>
  );
}

export default App;
